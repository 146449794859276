import FormPage from '@/components/FormPage'
import {
  createGetParams,
  createPostParams,
  reset,
} from '@/rest'

const formTitle = 'Transfer Black PIN'

export default {
  extends: FormPage,
  data () {
    return {
      breadcrumbs: [
        {
          text: 'Dealer Kits', disabled: false, to: '/dealer_kits',
        },
        {
          text: 'Black', disabled: false, to: '/black_kits',
        },
        {
          text: 'Black PIN', disabled: false, to: '/black_pin_kits',
        },
        {
          text: formTitle, disabled: true,
        },
      ],
      debounce: 1500,
      rest: {
        dealer: { data: null, error: null },
      },
      searchTimeout: null,
      step: 1,
      title: formTitle,
      // Form steps
      transferDetails: {
        fields: {
          pin: {
            component: () => import(/* webpackChunkName: "blackPinsField" */ '@/components/BlackPinsField'),
            name: 'Pin type',
            value: { text: 'B89DB', value: 'B89DB' },
          },
          pinBalance: {
            name: 'Pin balance',
          },
          targetDealer: {
            component: () => import(/* webpackChunkName: "textField" */ '@/components/TextField'),
            name: 'To Dealer',
            props: {
              hint: '',
              label: 'Enter dealer Id',
              loading: false,
              mask: 'NNNNNNNNNNN',
              maxlength: 11,
              persistentHint: true,
              required: true,
              rules: [
                val => !!val || 'Dealer Id required!',
                val => this.validTargetDealer(val),
              ],
              validateOnBlur: true,
            },
          },
          quantity: {
            component: () => import(/* webpackChunkName: "quantityField" */ '@/components/QuantityField'),
            name: 'Quantity',
            props: {
              hint: 'Minimum 1 unit',
              label: 'Enter quantity',
              maxlength: 6,
              required: true,
              rules: [
                val => Number(val) >= 1 || 'Minimum 1 unit!',
              ],
            },
          },
        },
        subTitle: 'Transfer to Black dealer',
        title: 'Transfer Details',
      },
      submission: {
        fields: {
          targetDealer: {
            name: 'To Dealer',
          },
          quantity: {
            emphasize: true,
            name: 'Quantity',
          },
        },
        submit: false,
        errorMessage: null,
        status: null,
        subTitle: 'Check before submit',
        title: 'Submit',
      },
    }
  },
  computed: {
    // Override
    formSteps: function () {
      return {
        transferDetails: this.transferDetails,
        submission: this.submission,
      }
    },
    quantity: function () {
      const quantity = this.transferDetails.fields.quantity.value
      return Number.isNaN(quantity) ? 0 : Number(quantity)
    },
    pinBalance: function () {
      return this.transferDetails.fields.pinBalance
    },
    targetDealer: function () {
      return this.transferDetails.fields.targetDealer
    },
  },
  watch: {
    'rest.dealer': {
      handler: function (val) {
        this.targetDealer.props.loading = false

        const data = val.data
        if (data && data.black) {
          this.targetDealer.props.hint = data.msisdn + ', ' + data.name
          this.submission.fields.targetDealer.value = data.dealer + ': ' + data.name
        } else if (val.error) {
          this.targetDealer.validate(true)
        }
      },
      deep: true,
    },
    quantity: function (val) {
      if (val > this.pinBalance.value) {
        this.transferDetails.fields.quantity.validate && this.transferDetails.fields.quantity.validate(true)
      } else {
        this.submission.fields.quantity.value = val
        this.transferDetails.fields.quantity.validate && this.transferDetails.fields.quantity.validate(false)
      }
    },
    'targetDealer.value': function (val) {
      clearTimeout(this.searchTimeout)
      reset(this.rest.dealer)

      this.targetDealer.validate && this.targetDealer.validate()
      this.targetDealer.props.hint = ''
      if (!val) return

      this.$nextTick(() => { this.targetDealer.props.loading = true })

      const params = createGetParams({
        target: val,
      })

      this.searchTimeout = setTimeout(() => {
        this.$rest.get('getDealer.php', params)
          .then(response => {
            if (response.data.self) this.rest.dealer.error = 'Self transfer not allowed!'
            else if (response.data.black) this.rest.dealer.data = response.data
            else this.rest.dealer.error = 'Not a Black dealer!'
            this.targetDealer.props.loading = false
          }).catch(error => {
            this.rest.dealer.error = this.invalidDealer(error)
            this.targetDealer.props.loading = false
          })
      }, this.debounce)
    },
  },
  mounted: function () {
    this.transferDetails.fields.quantity.props.rules.push(
      val => Number(val) <= this.transferDetails.fields.pinBalance.value || 'Insufficient Pin balance!',
    )
    const params = createGetParams({
      b89db_pin: true,
    })
    this.$rest.get('getDealerResources.php', params).then(function (response) {
      this.pinBalance.value = response.data.b89dbPin
    }.bind(this)).catch(e => {
      this.pinBalance.value = e.message + ': ' + e.response.data
    })
  },
  methods: {
    // Override
    async submit () {
      const params = createPostParams({
        action: 'transferBlackPin',
        payload: {
          type: 'B89DB',
          target: this.targetDealer.value,
          quantity: this.quantity,
        },
      })

      try {
        const response = await this.$rest.post('postAction.php', params)
        this.$nextTick(() => {
          this.alertText = 'Your request to transfer Black pin was successfully submitted.'
          this.status = this.submission.status = 'success'
          this.submission.submit = false
          this.isImpersonated() && this.showSubmissionResponse(response.data)
        })
      } catch (e) {
        this.$nextTick(() => {
          this.status = this.submission.status = 'error'
          this.submission.errorMessage = e.message + ': ' + e.response.data
          this.submission.submit = false
        })
      }
    },
    invalidDealer: function (error) {
      this.rest.dealer.error = error

      if (error.response.status === 404) return 'Dealer not found'
      else return error.message
    },
    validTargetDealer: function () {
      return this.rest.dealer.error ? this.rest.dealer.error : true
    },
  },
}
